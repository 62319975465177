<template>
  <div
    v-if="baseStorageLocation"
    class="pageWrapper"
  >
    <PrevPage title="Back to storages" />
    <SaveDiscardMenu
      :baseItem="baseStorageLocation"
      :item="storageLocation"
      :discardChanges="discardChanges"
      :saveChanges="saveChanges"
      :isChanged="isChanged"
      :title="baseStorageLocation.name"
      :displayDelete="true"
      :delete="{
        message: 'Are you sure you want to delete ' + baseStorageLocation.name + '?',
        execute: deleteStorage
      }"
    />

    <div class="box">
      <div class="boxItem">
        <p>Storage name</p>
        <div class="inputWrapper">
          <input
            type="text"
            placeholder="Storage name"
            v-model="storageLocation.name"
          />
        </div>
      </div>
      <br />
      <div class="boxItem">
        <p>Shop connection</p>
        <select
          class="inputShop"
          v-model="storageLocation.shop_id"
        >
          <option :value="null">Standalone</option>
          <option
            v-for="(shop, i) in shops"
            :key="i"
            :value="shop.id"
          >
            {{ shop.locationName }}
          </option>
        </select>
      </div>
      <br />
      <div class="boxItem">
        <p>Group</p>
        <select
          class="inputShop"
          v-model="storageLocation.tag_id"
        >
          <option :value="null">No group selected</option>
          <option
            v-for="(tag, i) in tags"
            :key="i"
            :value="tag.id"
          >
            {{ tag.name }}
          </option>
        </select>
        <br />
        <br />
        <div>
          <input
            type="text"
            v-model="newGroup"
            v-slugify
            placeholder="Add new group"
          />
          <button
            @click="addNewGroup"
            class="inputShop"
            style="width: 40px; margin: 0 5px"
          >
            +
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PrevPage from '@/components/PrevPage'
import SaveDiscardMenu from '@/components/SaveDiscardMenu'
import axios from 'axios'

export default {
  components: {
    PrevPage,
    SaveDiscardMenu,
  },
  data() {
    return {
      storageLocation: null,
      baseStorageLocation: null,
      shops: null,
      tags: [],
      newGroup: undefined
    }
  },
  computed: {
    isChanged() {
      if (JSON.stringify(this.storageLocation) !== JSON.stringify(this.baseStorageLocation)) {
        return true
      }
      return false
    },
  },
  created() {
    this.getStorageLocation()
    this.getShops()
  },
  methods: {
    addNewGroup() {
      let _this = this
      axios
        .post('storage/addNewTag', { tag: this.newGroup })
        .then(function (response) {
          _this.newGroup = ''
          _this.selectedTag = response.data.insertId
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    deleteStorage() {
      let _this = this
      axios
        .post('storage/deleteStorage', { storageLocationId: this.$route.params.storageLocationId, })
        .then(function (response) {
          _this.$router.back()
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    updateStorage() {
      axios.post('storage/updateStorage', {
        shopName: this.storageLocation.name,
        shopId: this.storageLocation.shop_id,
        storageLocationId: this.$route.params.storageLocationId,
        tagId: this.storageLocation.tag_id
      })
        .then(res => {
          this.baseStorageLocation = JSON.parse(JSON.stringify(this.storageLocation))
        })
        .catch(err => {
          if (err.response) {
            // TODO - Fix ALERT
            alert(err.response.data.msg)
          }
        })
    },
    getShops() {
      let _this = this
      axios
        .post('storage/getOnlyShops', {})
        .then(function (response) {
          _this.shops = JSON.parse(JSON.stringify(response.data))
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    getStorageLocation() {
      let _this = this
      axios
        .post('storage/getStorage', {
          storageLocationId: this.$route.params.storageLocationId,
        })
        .then(function (response) {
          _this.storageLocation = JSON.parse(JSON.stringify(response.data))
          _this.baseStorageLocation = JSON.parse(JSON.stringify(response.data))
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    discardChanges() {
      this.storageLocation = this.baseStorageLocation
      this.currentShopSelection = this.baseCurrentShopSelection
    },
    saveChanges() {
      this.updateStorage()
    },
  },
}
</script>

<style scoped lang="scss">
.box {
  width: 30%;
  position: relative;
  background: white;
  border-radius: 5px;
  padding: 20px;
  display: block;
}

.box div p {
  margin: 5px 0;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  letter-spacing: 1px;
}

.inputWrapper {
  display: flex;
}

.box input,
.box textarea {
  width: 100%;
  border-radius: 5px;
  resize: none;
  padding: 10px;
  border: 1px solid #c0c0c0;
}
.inputShop {
  width: 100%;
  border-radius: 5px;
  resize: none;
  padding: 10px;
  border: 1px solid #c0c0c0;
}

@media only screen and (max-width: 1000px) {
  .box {
    width: 100%;
  }
}
</style>